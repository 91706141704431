import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './sass/style.scss';

import logo from './images/krauss.svg';
import ShowTime from './components/ShowTime';
import Slide1Grid from './components/Slide1Grid';
import Slide3Grid from './components/Slide3Grid';
import ScreenReceiver from './components/ScreenReceiver';
import WindowAnimation from './components/WindowAnimation';
import SportAnimation from './components/SportAnimation';

function App() {

  const [loading, setLoading] = useState(true);

  const [musicOn, setMusicOn] = useState(false);

  const [windowAnimationOpen, setWindowAnimationOpen] = useState(false);
  const [windowAnimationClose, setWindowAnimationClose] = useState(false);
  const [sportAnimation, setSportAnimation] = useState(false);

  useEffect(() => {
    // var siteID = window.location.hash.replace('#', '');
    setLoading(false);

    setInterval(() => {
      var currentHour = new Date().getHours();
      var currentMinute = new Date().getMinutes();
      var currentSeconds = new Date().getSeconds();
      if(currentSeconds === 0) {
        if(currentMinute === 40) {
          // *:40:00 -> Window Reminder
          setWindowAnimationOpen(true);
        }
        if(currentMinute === 43) {
          // *:43:00 -> Window Reminder
          setWindowAnimationClose(true);
        }
        if( (currentHour === 8 && currentMinute === 50) || (currentHour === 10 && currentMinute === 20) ||
            (currentHour === 11 && currentMinute === 50) || (currentHour === 13 && currentMinute === 20) ||
            (currentHour === 14 && currentMinute === 50) || (currentHour === 16 && currentMinute === 20)) {
              setSportAnimation(true);
            }
      }
    }, 1000);
  }, []);

  if(loading) return <div className="pageloader"></div>

  return (

    <div className="main-slider">

      {!musicOn && <button onClick={e => setMusicOn(true)}>Enable Music</button>}

      {windowAnimationOpen && <WindowAnimation text="FENSTER ÖFFNEN" whenDone={e => setWindowAnimationOpen(false)} />}
      {windowAnimationClose && <WindowAnimation text="FENSTER SCHLIESSEN" whenDone={e => setWindowAnimationClose(false)} />}

      {sportAnimation && <SportAnimation whenDone={e => setSportAnimation(false)} />}

      <div id="topline">
        <img src={logo} alt="Krauss Kommunikation" />
        <span className="time"><ShowTime /></span>
      </div>

      <div className="index">
        <Slider dots={false} fade={true} infinite={true} autoplaySpeed={30000} speed={500} slidesToShow={1}
        slidesToScroll={1} autoplay={true} vertical={false} slidesPerRow={1} arrows={false}>
          <div className="slide slide-1">
            <Slide1Grid />
          </div>
          {/* <div className="slide slide-2">
            <h1>Slide 2</h1>
          </div> */}
          <div className="slide slide-3">
            <Slide3Grid />
          </div>
        </Slider>
      </div>

      <ScreenReceiver />

    </div>
  );
}

export default App;
