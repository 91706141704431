import { useEffect, useState } from "react";
import { HiStar, HiUser, HiUserAdd } from "react-icons/hi";
import API from "../toolbox/API";

function SiteInfo(props) {

    const [infos, setInfos] = useState({
        visitors: 0, new: 0, conversions: 0
    });

    useEffect(() => {
        update();
        setInterval(() => {
            update();
        }, 1000*30);
    }, []);

    const update = () => {
        API.call('tv/visitors.php', data => {
            var inf = infos;
            data.data.forEach(element => {
                if(element.name === 'Besucher') inf.visitors = element.value;
                if(element.name === 'Neu') inf.new = element.value;
                if(element.name === 'Conversions') inf.conversions = element.value;
            });
            setInfos(inf);
        })
    }

    return <>
    
    <div className="visitor-total">
        <div className="info-card site-info">
            <div className="title"><HiUser /> <span>Besucher</span></div>
            <div className="content">
                <div className="visitor-info">{infos.visitors}</div>
            </div>
        </div>
    </div>

    <div className="visitor-new">
        <div className="info-card site-info">
            <div className="title"><HiUserAdd /> <span>Neue Besucher</span></div>
            <div className="content">
                <div className="visitor-info">{infos.new}</div>
            </div>
        </div>
    </div>

    <div className="visitor-convs">
        <div className="info-card site-info">
            {/* <div className="title"><HiStar /> <span>Conversions</span></div>
            <div className="content">
                <div className="visitor-info">{infos.conversions}</div>
            </div> */}
            <div className="title"><HiStar /> <span>Wiederkehrend</span></div>
            <div className="content">
                <div className="visitor-info">{infos.visitors - infos.new}</div>
            </div>
        </div>
    </div>
    
    </>

}

export default SiteInfo;