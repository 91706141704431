import { HiGlobe, HiLightningBolt, HiSun } from "react-icons/hi";

function Energy(props) {

    return <>
    
    <div className="co2">
        <div className="info-card site-info">
            <div className="title"><HiGlobe /> <span>CO2 Ersparnis</span></div>
            <div className="content">
                <div className="visitor-info">0 <small>Tonnen</small></div>
            </div>
        </div>
    </div>

    <div className="energy">
        <div className="info-card site-info">
            <div className="title"><HiLightningBolt /> <span>Energiespeicher</span></div>
            <div className="content">
                <div className="visitor-info">0 <small>kWh</small></div>
            </div>
        </div>
    </div>

    <div className="solar">
        <div className="info-card site-info">
            <div className="title"><HiSun /> <span>Solarenergie</span></div>
            <div className="content">
                <div className="visitor-info">0 <small>kWh</small></div>
            </div>
        </div>
    </div>
    
    </>

}

export default Energy;