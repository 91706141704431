const API = {

    call: (url, callback, error) => {
        fetch("https://api.monitoring.krauss-webstats.de/" + url)
            .then(data => data.json())
            .then(callback)
            .catch(error);
    }

}

export default API;