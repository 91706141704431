import { useEffect } from "react";

function SportAnimation(props) {

    useEffect(() => {
        var audios = [
            "NightRunAway-AnJone.mp3"
        ];
        var audio = new Audio('/sportsounds/' + audios[Math.floor(Math.random() * audios.length)]);
        audio.play();
        audio.volume = 0.06;
        setTimeout(() => {
            audio.pause();
            audio.remove();
        }, 15000);
        document.querySelector('.sport-wrapper').classList.add('active');
        setTimeout(() => {
            document.querySelector('.sport-wrapper').classList.remove('active');
            setTimeout(() => {
                if(props.whenDone) props.whenDone();
            }, 2200);
        }, 15000);
    })

    return (<div className="sport-wrapper">
        <div className="inner">
            <img src="/streckingmenschen.gif" alt="Running man" />
            <div className="title">Activity time!</div>
        </div>
    </div>)

}

export default SportAnimation;