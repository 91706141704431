import { useEffect, useState } from "react";
import { HiBookOpen } from "react-icons/hi";
import API from "../toolbox/API";

function SiteTrends(props) {

    const [sites, setSites] = useState([]);

    useEffect(() => {
        update();
        setInterval(() => {
            update();
        }, 1000*30);
    }, []);

    const update = () => {
        API.call('tv/trendsites.php', data => {
            setSites(data.data);
        });
    }

    return (<div className="info-card site-trends">
        <div className="title"><HiBookOpen /> <span>Seiten im Trend</span></div>
        <div className="content">
            <div className="trendlist">
                {sites.map((site, index) => (
                    <div key={index} className="listitem">
                        <span className="label">{site.visitors}</span>
                        <span className="site">{site.name.split("?")[0]}</span>
                    </div>
                ))}
            </div>
        </div>
    </div>)

}

export default SiteTrends;