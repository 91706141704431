import { useEffect, useState } from "react";
import { HiAtSymbol } from "react-icons/hi";
import API from "../toolbox/API";

function SiteReferrers(props) {

    const [referrers, setReferrers] = useState([]);
    const [highest, setHighest] = useState(0);

    useEffect(() => {
        update();
        setInterval(() => {
            update();
        }, 1000*30);
    }, []);

    const update = () => {
        API.call('tv/referrers.php', data => {
            setReferrers(data.data.list);
            var highest = 0;
            data.data.list.forEach(element => {
                if(element.amount > highest) highest = element.amount;
            });
            setHighest(highest * 1.2);
        });
    }

    return (<div className="info-card site-referrers">
        <div className="title"><HiAtSymbol /> <span>Kanäle</span></div>
        <div className="content">
            <div className="ref-dia">
                {referrers.map((ref, index) => (
                    <div key={index} className="item">
                        <div className="label">{ref.name}</div>
                        <div className="bar">
                            <div className="bar-inner" style={{width: (ref.amount / highest * 100) + "%"}}></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>)

}

export default SiteReferrers;