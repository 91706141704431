import { useEffect, useState } from "react";
import Slider from "react-slick";
import { niceDate } from "./Calendar";

function NewsFeed(props) {

    const [news, setNews] = useState([]);

    useEffect(() => {
        update();
        setInterval(() => {
            update();
        }, 1000*60*5);
    }, []);

    const update = () => {
        // const url = "https://www.tagesschau.de/xml/rss2/";
        const url = "https://api.monitoring.krauss-webstats.de/corsreader.php?url=https://www.designtagebuch.de/feed/";
        fetch(url)
            .then(response => response.json())
            .then(data => data.data)
            .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
            .then(data => {
                var items = data.querySelectorAll('item');
                var news = [];
                items.forEach(item => {
                    console.log(item);
                    if(news.length < 5){
                        var desc = item.querySelector('description').innerHTML;
                        desc = /(<!\[CDATA\[)(.*?)(\]\]>)/.exec(desc)[2];
                        var image = "https://www.tagesschau.de/stoerungsbild100~_v-gross20x9.jpg";
                        news.push({
                            title: item.querySelector('title').innerHTML,
                            date: item.querySelector('pubDate').innerHTML,
                            content: desc,
                            image: image
                        });
                    }
                });
                setNews(news);
            });
    }

    return (<div className="info-card site-news">
        <Slider dots={false} infinite={true} autoplaySpeed={12000} speed={400} slidesToShow={1}
            slidesToScroll={1} autoplay={true} vertical={false} slidesPerRow={1} arrows={false}>
            {news.map((item, index) => (
                <div key={index} className="news">
                    <div className="inner" style={{backgroundImage: 'url(' + item.image + ')'}}>
                        <div className="typo">
                            <div className="date">{niceDate(new Date(item.date))}</div>
                            <div className="title">{item.title}</div>
                            <div className="content">{item.content}</div>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>)

}

export default NewsFeed;