import { useEffect, useState } from "react";

function ShowTime(props) {

    const [time, setTime] = useState("");

    useEffect(() => {
        setTime(formatDate(new Date()));
    }, []);

    setInterval(() => {
        setTime(formatDate(new Date()));
    }, 1000);

    return time;

}

function formatDate(date) {
    var d = new Date(date),
        hour = '' + d.getHours(),
        mins = '' + d.getMinutes(),
        secs = '' + d.getSeconds();

    if (hour.length < 2) 
        hour = '0' + hour;
    if (mins.length < 2) 
        mins = '0' + mins;
    if (secs.length < 2) 
        secs = '0' + secs;

    return [hour, mins, secs].join(':');
}

export default ShowTime;