function SiteGoals(props) {

    return (<div className="info-card site-goals">
        <div className="title">Definierte Ziele</div>
        <div className="content">
            <p>Diese Anzeige ist derzeit nicht verfügbar.</p>
        </div>
    </div>)

}

export default SiteGoals;