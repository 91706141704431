import SiteGoals from "./SiteGoals";
import SiteInfo from "./SiteInfo";
import SiteReferrers from "./SiteReferrers";
import SiteTrends from "./SiteTrends";

function Slide1Grid(props) {

    return (<div className="slide1-grid">
        <div className="trends">
            <SiteTrends />
        </div>
        <div className="referrers">
            <SiteReferrers />
        </div>
        <SiteInfo />
        <div className="goals">
            <SiteGoals />
        </div>
    </div>)

}

export default Slide1Grid;