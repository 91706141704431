import { useEffect, useState } from "react";
import ICAL from "ical.js";
import { HiCalendar } from "react-icons/hi";

function Calendar(props) {

    const [events, setEvents] = useState({
        today: [],
        tomorrow: [],
        third: []
    });

    useEffect(() => {
        // const url = 'https://agenturkrauss.prosonata.software/userdata/agenturkrauss_4Hr6K9Of48Cr38Se2f3/Urlaub.ics';
        // const url = 'https://agenturkrauss.prosonata.software/userdata/agenturkrauss_4Hr6K9Of48Cr38Se2f3/alle_Projektaufgaben_Meilensteine.ics';
        const url = 'https://agenturkrauss.prosonata.software/userdata/agenturkrauss_4Hr6K9Of48Cr38Se2f3/Projekt_Meilensteine.ics';
        fetch("https://api.monitoring.krauss-webstats.de/corsreader.php?url=" + url).then(data => data.json()).then(data => {
            var caldata = data['data'];
            var result = ICAL.parse(caldata);
            var events = result[2];

            var evs = {
                today: [],
                tomorrow: [],
                third: []
            };
            var nowDate = new Date(); nowDate.setHours(0,0,0,0);
            var current = Math.floor(nowDate.getTime());
            var tomorrow = current + (1000*60*60*24);
            var third = tomorrow + (1000*60*60*24);

            events.map(event => {
                if(event[0] !== 'vevent') return;
                var content = event[1][6][3];
                var start = event[1][4][3];
                var end = event[1][5][3];
                var startT = new Date(start);
                startT.setHours(0,0,0,0);
                startT = startT.getTime();
                var endT = new Date(end);
                endT.setHours(0,0,0,0);
                endT = endT.getTime();

                var elem = {
                    start: start,
                    startTime: startT,
                    end: end,
                    endTime: endT,
                    content: content
                };
                if(isInRange(startT, endT, current)) {
                    evs.today.push(elem);
                }else if(isInRange(startT, endT, tomorrow)) {
                    evs.tomorrow.push(elem);
                }else if(isInRange(startT, endT, third)) {
                    evs.third.push(elem);
                }

                return event;
            })

            setEvents(evs);
        });
    }, []);

    return (<div className="info-card site-calendar">
        <div className="title"><HiCalendar /> <span>Meilensteine</span></div>
        <div className="content">
            
            <div className="cal-title">Heute</div>
            {events.today.length === 0 && <div className="cal-spacer"></div>}
            {events.today.map(event => (<div key={event.startT * Math.random()} className="cal-entry">
                <span className="label">{event.content}</span>
            </div>))}

            <div className="cal-title">Morgen</div>
            {events.tomorrow.length === 0 && <div className="cal-spacer"></div>}
            {events.tomorrow.map(event => (<div key={event.startT * Math.random()} className="cal-entry">
                <span className="label">{event.content}</span>
            </div>))}

            <div className="cal-title">Übermorgen</div>
            {events.third.length === 0 && <div className="cal-spacer"></div>}
            {events.third.map(event => (<div key={event.startT * Math.random()} className="cal-entry">
                <span className="label">{event.content}</span>
            </div>))}

        </div>
    </div>)

}

export default Calendar;

export function niceDate(date) {
    var day = date.getDate();
    var month = date.getMonth();
    month = month+1;
    if((String(day)).length===1)
    day='0'+day;
    if((String(month)).length===1)
    month='0'+month;

    return day+ '.' + month + '.' + date.getFullYear();
}

function isInRange(a, b, needed){
    return (a <= needed && b >= needed)
}