import { useEffect } from "react";

function WindowAnimation(props) {

    useEffect(() => {
        var audio = new Audio('https://tvkonzept.krauss-kommunikation.com/wp-content/themes/nastanie33/images/wind.mp3');
        audio.play();
        setTimeout(() => {
            audio.pause();
            audio.remove();
        }, 15000);
        document.querySelector('#window-opener').classList.add('active');
        setTimeout(() => {
            document.querySelector('#window-opener').classList.add('active-image');
            setTimeout(() => {
            document.querySelector('#window-opener').classList.add('active-text');
            setTimeout(() => {
                document.querySelector('#window-opener').classList.remove('active-text');
                document.querySelector('#window-opener').classList.remove('active-image');
                document.querySelector('#window-opener').classList.remove('active');
                setTimeout(() => {
                    if(props.whenDone) props.whenDone();
                }, 2200);
            }, 13000);
            }, 1000);
        }, 1800);
    }, [props]);

    return (<div className="window-animation">
        <div id="window-opener">
            <div className="wave wave1"></div>
            <div className="wave wave2"></div>
            <div className="wave wave3"></div>
            <div className="wave wave4"></div>
            <div className="wave wave5"></div>
            <div className="wave wave6"></div>
            <div className="back"></div>
            <div className="image">
                <img alt="Hexe :D" src="https://www.nintendo.com/content/dam/noa/en_US/games/switch/b/bibi-blocksberg-big-broom-race-3-switch/Switch_BibiBlocksberg-BigBroomRace3_description-char.png" />
            </div>
            <div className="text wnd-text">{props.text}</div>
        </div>
    </div>)

}

export default WindowAnimation;