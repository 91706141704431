import Calendar from "./Calendar";
import Energy from "./Energy";
import Infoboard from "./Infoboard";
import NewsFeed from "./NewsFeed";

function Slide3Grid(props) {

    return (<div className="slide3-grid">
        <div className="news">
            <NewsFeed />
        </div>
        <div className="calendar">
            <Calendar />
        </div>

        <Energy />

        {/* <div className="infoboard">
            <Infoboard />
        </div> */}
    </div>)

}

export default Slide3Grid;